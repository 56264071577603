
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class ListelemeTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() hideDetails!: string | boolean;

  turler = [
    {id: 1, name: 'Tümü'},
    {id: 2, name: 'Esas No Aralığı ile'},
    {id: 3, name: 'Taraf Bilgileri Kişi ile'},
    {id: 4, name: 'Taraf Bilgileri Kurum ile'},
    {id: 5, name: 'Açılış tarihi'},
    {id: 6, name: 'Kapanış tarihi'},
    {id: 7, name: 'Son 1 gün'},
    {id: 8, name: 'Son 1 hafta'},
    {id: 9, name: 'Son 2 hafta'},
    {id: 10, name: 'Son 1 ay'},
    {id: 11, name: 'İcraPro Dosyalarım'},
  ];

  mounted(){
    this.localValue=1;
  }

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }
}
