
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import YargiTuruPicker from "@/components/pickers/YargiTuruPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import ListelemeTuruPicker from "@/components/pickers/ListelemeTuruPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import * as XLSX from 'xlsx';
import {ProgramPaths} from "@/enum/ProgramPaths";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import {AvukatIcraDosyalariTalep} from "@/plugins/uyap-plugin/uyap/AvukatIcraDosyalari";
import {IAvukatDosya} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

@Component({
  components: {Dates, ListelemeTuruPicker, YargiTuruPicker}
})

export default class GenelIslemFormWizard extends Mixins(ObjectInputMixin) {
  @Prop({default: ["Listelenecek Takipler", "Takipleri Seç", "İşlemler", "İşlemler Sıraya Alındı"]}) stepperHeaders!: Array<string>;
  @Prop() showSuccessCloseButton!: string | boolean;
  @Prop() hideNextButton!: string | boolean;
  @Prop({default: false}) onFly!: boolean;
  @Prop({default: 'Yeni işlem için'}) successMessage!: string;

  activeStep: number = 1;
  tablodaAra = '';
  takipSecilmediError = false;
  localValue: GenelIslemEntity = new GenelIslemEntity();
  taskDurum = TaskDurum;
  uyapTakipLoadStatus = {
    loading: false,
    error: false,
    errorMessage: ''
  };

  headers = [
    {text: "Birim Adı", value: "birimAdi"},
    {text: "Dosya Esas No", value: "dosyaNo"},
    {text: "Açılış Tarihi", value: "dosyaAcilisTarihi"},
    {text: "Dosya Durumu", value: "dosyaDurum"}
  ];

  get isShowSuccessCloseButton() {
    return this.showSuccessCloseButton || this.showSuccessCloseButton === "";
  }

  get isHideNextButton() {
    return this.hideNextButton || this.hideNextButton === "";
  }

  @Watch('localValue.secilen_takipler')
  onSecilenTakiplerChange() {
    this.takipSecilmediError = false;
  }

  fileRule(file: any) {
    if (file) {
      let uzanti = file.name.split('.').pop();
      if (["xls", "XLS"].indexOf(uzanti) >= 0)
        return true;
      else
        return "Sadece xls dosya yüklenebilir.";
    } else
      return true;
  }

  async getTakipler() {
    this.$nextTick(async () => {
      try {
        this.uyapTakipLoadStatus.loading = true;
        this.localValue.secilen_takipler=[];
        if (this.localValue.excel) {
          let f = this.localValue.excel;
          let reader = new FileReader();
          reader.onload = (e: any) => {
            let data = e.target.result;
            let workbook = XLSX.read(data, {type: 'binary'});
            let first_sheet_name = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[first_sheet_name];
            let excelData: any = XLSX.utils.sheet_to_json(worksheet, {raw: true, blankrows: false});
            excelData.map((takip: any, index: number) => (takip.index = index));
            this.localValue.takipler = excelData;
          };
          reader.readAsBinaryString(f);
        } else {
          // talepleri burda oluştur
          let talep: AvukatIcraDosyalariTalep = new AvukatIcraDosyalariTalep();
          talep.dosyaDurumKod = this.localValue.filtre.dosyaKapaliMi;
          if (this.localValue.filtre.listeleme_turu == 3) {
            talep.setKisi(this.localValue.filtre.adi,this.localValue.filtre.soyadi,this.localValue.filtre.tcKimlikNo);
          } else if (this.localValue.filtre.listeleme_turu == 4) {
            talep.setKurum(this.localValue.filtre.kurumAdi, this.localValue.filtre.mersisNo, this.localValue.filtre.vergiNo);
          } else if (this.localValue.filtre.listeleme_turu == 5) {
            talep.setAcilisTarihAraligi(this.localValue.filtre.baslangicTarihi, this.localValue.filtre.bitisTarihi);
          } else if (this.localValue.filtre.listeleme_turu == 6) {
            talep.setKapanisTarihAraligi(this.localValue.filtre.baslangicTarihi, this.localValue.filtre.bitisTarihi);
          } else if (this.localValue.filtre.listeleme_turu == 7) {//son 1 gün
            let baslangicTarihi = new Date();
            baslangicTarihi.setDate(baslangicTarihi.getDate() - 1);
            talep.setAcilisTarihAraligi(baslangicTarihi.toISOString(), new Date().toISOString());
          } else if (this.localValue.filtre.listeleme_turu == 8) {//son 1 hafta
            let baslangicTarihi = new Date();
            baslangicTarihi.setDate(baslangicTarihi.getDate() - 7);
            talep.setAcilisTarihAraligi(baslangicTarihi.toISOString(), new Date().toISOString());
          } else if (this.localValue.filtre.listeleme_turu == 9) {//son 2 hafta
            let baslangicTarihi = new Date();
            baslangicTarihi.setDate(baslangicTarihi.getDate() - 14);
            talep.setAcilisTarihAraligi(baslangicTarihi.toISOString(), new Date().toISOString());
          } else if (this.localValue.filtre.listeleme_turu == 10) {//son 1 gün
            let baslangicTarihi = new Date();
            baslangicTarihi.setMonth(baslangicTarihi.getMonth() - 1);
            talep.setAcilisTarihAraligi(baslangicTarihi.toISOString(), new Date().toISOString());
          }
          let takipler: any;
          if (this.localValue.filtre.listeleme_turu == 2)   // aralık ile dosya sorgula
            takipler = await this.$uyap.AvukatIcraDosyalari().esasNoAraligi(talep, this.localValue.filtre.dosyaYil, this.localValue.filtre.dosyaSira, this.localValue.filtre.dosyaSira2);
          else if (this.localValue.filtre.listeleme_turu == 11) { // icrapro dosyaları
            let takiplerRes = await this.$http.get('/api/v1/uyap-dosya-listele');
            takipler = {items: []};
            takiplerRes.data.forEach((takip: {
              id: number,
              icra_dairesi_id: number,
              dosya_esas_no: string,
              uyap_dosya_id: string,
              icra_dairesi: IcraDairesiEntity
            }) => (takipler.items.push({
              birimAdi: takip.icra_dairesi?.isim,
              dosyaNo: takip.dosya_esas_no,
              dosyaId: takip.uyap_dosya_id
            })));
          } else{
            takipler = await this.$uyap.AvukatIcraDosyalari().recursive(talep);
          }
          takipler.items.map((takip: any, index: number) => (takip.index = index));
          this.localValue.takipler = takipler.items;
        }
      } catch (e) {
        this.uyapTakipLoadStatus.errorMessage = e.message;
        this.uyapTakipLoadStatus.error = true;
      } finally {
        this.uyapTakipLoadStatus.loading = false;
      }
    })
  }

  selectAll() {
    if (this.localValue.secilen_takipler.length == this.localValue.takipler.length) {
      this.localValue.secilen_takipler = []
    } else {
      this.localValue.secilen_takipler = [...this.localValue.takipler];
    }
    this.input();
  }

  downloadOrnekExcel() {
    window.open('/excel_ornek.xls')
  }

  success() {
    this.activeStep = 1;
    this.localValue.secilen_takipler = [];
    this.$emit('onSuccess');
  }

  nextStep() {
    if (this.activeStep == 1) {
      this.getTakipler();
      this.activeStep++;
    } else if (this.activeStep == 2) {
      if (this.localValue.secilen_takipler.length == 0) this.takipSecilmediError = true;
      else this.activeStep++;
    } else if (this.activeStep < this.stepperHeaders.length) {
      this.activeStep++;
    }
    if (this.activeStep >= this.stepperHeaders.length) {
      this.$emit('final');
    }
    this.$emit('step', this.activeStep);
    this.focusStep(this.activeStep);
  }

  previousStep() {
    if (this.activeStep > 1) {
      this.activeStep--;
      this.uyapTakipLoadStatus = {
        loading: false,
        error: false,
        errorMessage: ''
      };
    }
    this.$emit('step', this.activeStep);
    this.focusStep(this.activeStep);
  }

  islemlereGit() {
    this.$router.push(ProgramPaths.eicrapro + '/islemler');
  }

  sifirla() {
    this.localValue = new GenelIslemEntity();
    this.input();
    this.activeStep = 1;
  }

  focusStep(step: any) {
    let activeHeader = (this.$refs['step' + step] as Vue[])[0].$el;
    let headers = this.$refs['headers'] as Element;
    let activeHeaderRight = activeHeader.getBoundingClientRect().right;
    let headersLeft = headers.getBoundingClientRect().left;
    let headersWidth = headers.getBoundingClientRect().width;
    let scrollPosition = (activeHeaderRight - headersLeft) - headersWidth;
    if (scrollPosition < 0) {
      scrollPosition = 0;
    }
    headers.scrollLeft += scrollPosition;
  }
}

export class GenelIslemEntity {
  filtre: TakipFiltreEntity = new TakipFiltreEntity();
  excel!: File;
  takipler: Array<IAvukatDosya> = [];
  secilen_takipler: Array<IAvukatDosya> = [];
}

class TakipFiltreEntity {
  dosyaKapaliMi: 0|1 = 0;
  yargiTuru: number = 2;
  yargiBirimi: any = '1101';
  mahkeme: string = "";
  listeleme_turu!: any;
  dosyaYil!: number;
  dosyaSira!: number;
  dosyaSira2!: number;
  baslangicTarihi: string = "";
  bitisTarihi: string = "";
  dosyaKapanisBaslangisTarihi: string = "";
  dosyaKapanisBitisTarihi: string = "";
  tcKimlikNo: string = '';
  adi: string = '';
  soyadi: string = '';
  kurumAdi: string = '';
  mersisNo: string = '';
  vergiNo: string = '';
}
