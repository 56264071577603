
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class YargiTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() hideDetails!: string | boolean;

  turler = [
    {id: 0, name: "Ceza",},
    {id: 1, name: "Hukuk",},
    {id: 2, name: "İcra",},
    {id: 6, name: "İdari Yargı",},
    {id: 11, name: "Satış Memurluğu",},
    {id: 25, name: "Arabuluculuk",}
  ];

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }
}
